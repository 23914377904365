import React, {useMemo, useCallback, useEffect, useState} from 'react';
import entries from '../Components/ProfileLayout/entries';
const appConfig = require('../data.json');

export default function useProfileLayout({pathname, tabName}) {
  const isActive = useCallback((url, pathname = '') => {
    return pathname.replace(/\//g, '') === url.replace(/\//g, '');
  }, []);

  useEffect(() => {
    console.log('tab change', tabName);
  }, [tabName]);

  const {parent, tab} = useMemo(() => {
    const parent = entries.find((e) => isActive(e.name, pathname));

    // parent.entries = (parent.entries || []).filter((e) =>
    //   appConfig.alpha ? true : !['reqMonthly'].includes(e.name),
    // );

    const child = parent.entries.find((e) => e.name === tabName);
    return {
      parent,
      tab: child || parent.entries[0],
    };
  }, [pathname, tabName]);

  return {
    parent,
    tab,
  };
}
