function isStaff(profile) {
  return (
    (profile && profile.staff_type === 'staff') || profile.staff_type === 'root'
  );
}

function isReviewer(profile) {
  return profile && profile.staff_type === 'reviewer';
}

function isVIP(profile) {
  return profile && profile.user_type.indexOf('vip') >= 0;
}

function isEnt(profile) {
  return profile && profile.user_type.indexOf('ent') >= 0;
}

function isMonthly(profile) {
  return profile.user_type.indexOf('monthly') >= 0;
}

export {isStaff, isReviewer, isVIP, isEnt, isMonthly};
