const config = require('../../data.json');
const entries = [
  {
    name: 'orders',
    label: '訂單總覽',
    subtitle: 'Orders',
    entries: [
      {name: 'order', label: '全部訂單'},
      {name: 'orderWaiting', label: '待付款訂單'},
      {name: 'orderItemRejected', label: '檔案未合格訂單'},
      {name: 'orderFinished', label: '付款已完成訂單'},
    ],
  },
  ...(config.isLiteVersion
    ? []
    : [
        {
          name: 'account_manage',
          label: '帳戶管理',
          subtitle: 'Account',
          entries: [
            {name: 'depositCredit', label: '點數加值'},
            {name: 'depositRecord', label: '加值訂單'},
            {name: 'creditsHistory', label: '點數歷史記錄'},
            {name: 'bonusHistory', label: '紅利歷史記錄'},
          ],
        },
      ]),
  {
    name: 'profile',
    label: '會員資料',
    subtitle: 'Profile',
    entries: [
      {name: 'myProfile', label: '基本資料'},
      {name: 'changePw', label: '修改登入密碼'},
      // {name: 'reqEnt', label: '申請成為企業會員'},
      {name: 'reqMonthly', label: '申請成為企業月結會員'},
      // { name: "address", label: "訂單常用地址" },
    ],
  },
  {
    name: 'monthly_manage',
    label: '月結服務',
    subtitle: 'Monthly',
    entries: [
      {name: 'currentPeriodOrder', label: '當期月結訂單列表'},
      {name: 'monthlyRecord', label: '已出帳對帳單列表'},
    ],
    // hidden: !isEnt(profile),
  },
  ...(config.client === 'eculture'
    ? [
        {
          name: 'periods',
          label: '訂閱制',
          subtitle: 'Period',
          entries: [{name: 'periodOrder', label: '訂閱制訂單'}],
        },
      ]
    : []),
  {
    name: 'logout',
    subtitle: 'Log out',
    label: '登出',
    type: 'function',
  },
];

export default entries;
