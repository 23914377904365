import React from 'react';
import {Row} from '../../Widgets';
const config = require('../../data.json');

export default function BreadCrumb({entry, leafNode}) {
  return (
    <Row style={{color: config.colors.textThird}} margin="0 0 20px 0">
      {' '}
      會員中心 <span style={{margin: '0 10px'}}>/</span> {entry?.label}{' '}
      <span style={{margin: '0 10px'}}>/</span> {leafNode?.label}
    </Row>
  );
}
