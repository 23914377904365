import React, {useContext} from 'react';
import {Row, Text} from '../../Widgets';
import styled from 'styled-components';
import {message, Upload, Tag} from 'antd';
import {USER_TYPE, MONTHLY_TYPE} from '../../dictionary';
import {Context} from '../../AppContext';
const config = require('../../data.json');

export default function Information() {
  const app = useContext(Context);
  const {profile} = app.state;

  async function editProfile({file}) {
    if (file) {
      app.actions.setLoading(true);
      try {
        await app.actions.editProfile({
          avatar: file,
        });
        await app.actions.getProfile();
        message.success('更換頭像成功');
      } catch (err) {
        console.warn(err);
        message.error('更換頭像錯誤');
      }
      app.actions.setLoading(false);
    }
  }

  return (
    <Row margin="0 0 20px 0">
      <Avatar
        avatar={
          profile.avatar === ''
            ? '/images/avatar-default.png'
            : `${profile.avatar}` || `${profile.social_avatar}`
        }>
        <Upload beforeUpload={() => false} fileList={[]} onChange={editProfile}>
          <AvatarEdit src="/images/avatar-edit-icon.svg" />
        </Upload>
      </Avatar>
      <div style={{flex: 1, marginLeft: 20}}>
        <Row>
          <Text style={{marginRight: 10}}>{profile.name}</Text>
          {profile.is_frozen && <Tag color="red">帳號已凍結</Tag>}
        </Row>
        <Row margin="0">
          <Text
            size="xs"
            color={config.colors.textThird}
            style={{marginRight: 10}}>
            #{profile.id}{' '}
          </Text>
          <Text
            size="xs"
            color={config.colors.textThird}
            style={{marginRight: 10}}>
            {{
              google: 'Google 登入',
              facebook: 'FB 登入',
            }[profile.social_type] || '一般信箱登入'}{' '}
          </Text>
          <Text
            size="xs"
            color={config.colors.textThird}
            style={{marginRight: 10}}>
            {USER_TYPE[profile.user_type] || ''}
          </Text>
          {!config.isLiteVersion && (
            <Text size="xs" color={config.colors.textThird}>
              {profile.group_name}
            </Text>
          )}
        </Row>
        {!config.isLiteVersion && (
          <Row margin="0">
            <Text size="xs" color={config.colors.textThird}>
              加值點數：
            </Text>
            <Text size="xs" color={config.colors.main}>
              {profile.credits} 點
            </Text>
          </Row>
        )}
        {!config.isLiteVersion && (
          <Row margin="0">
            <Text size="xs" color={config.colors.textThird}>
              紅利金：
            </Text>
            <Text size="xs" color={config.colors.main}>
              {profile.bonus} 元
            </Text>
          </Row>
        )}
        {profile.user_type === 'monthly' && (
          <div>
            <Row margin="0">
              <Text size="xs" color={config.colors.textThird}>
                月結結算日：
              </Text>
              <Text size="xs" color={config.colors.main}>
                {MONTHLY_TYPE[profile.monthly_type]}
              </Text>
            </Row>
            <Row margin="0">
              <Text size="xs" color={config.colors.textThird}>
                月結緩衝繳費期：
              </Text>
              <Text size="xs" color={config.colors.main}>
                {profile.monthly_pay_buffer} 天
              </Text>
            </Row>
            <Row margin="0">
              <Text size="xs" color={config.colors.textThird}>
                月結額度：
              </Text>
              <Text size="xs" color={config.colors.main}>
                {profile.monthly_quota} 元
              </Text>
              <div style={{flex: 1}} />
              <Text
                size="xs"
                color={config.colors.textThird}
                style={{marginRight: 3}}>
                當月已使用額度{' '}
              </Text>
              <Text size="xs" color={config.colors.main}>
                {profile.monthly_used_quota} 元
              </Text>
            </Row>
          </div>
        )}
      </div>
    </Row>
  );
}

const Avatar = styled.div`
  background-color: #dcdddd;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: url(${(props) => props.avatar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`;

const AvatarEdit = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;
