import React, {useContext, useEffect, useState} from 'react';
import UnAuthResult from '../../Components/UnAuthResult';
import {Context} from '../../AppContext';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Layout from '../../Components/ProfileLayout';
import useOrderTable from '../../hooks/use-order-table';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';
import LargeScreenTable from '../../Components/OrderTable/LargeScreenTable';
import SmallScreenTable from '../../Components/OrderTable/SmallScreenTable';
import useDimensions from '../../hooks/use-dimension';
const appConfig = require('../../data.json');
const qs = require('query-string');

export default function PeriodOrderListPage(props) {
  const app = useContext(Context);
  const pathname = props.location.pathname;
  const query = qs.parse(props.location.search);
  const {loading} = app.state;
  const {dimension} = useDimensions();
  const {orders, columns, filters, total, changeFilters} = useOrderTable({
    pathname: props.location.pathname,
    query,
    defaultType: ORDER_TABLE_TYPES.PERIOD,
  });

  if (!app.state.profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <Layout pathname={pathname} tabName={query.tabName}>
        {({tab}) => {
          return tab.name === 'periodOrder' ? (
            <div>
              {dimension.innerWidth > appConfig.breakpoints.sm ? (
                <LargeScreenTable
                  loading={loading}
                  orders={orders}
                  columns={columns}
                  filters={filters}
                  setFilters={changeFilters}
                  total={total}
                />
              ) : (
                <SmallScreenTable
                  loading={loading}
                  orders={orders}
                  columns={columns}
                  filters={filters}
                  setFilters={changeFilters}
                  total={total}
                />
              )}
            </div>
          ) : null;
        }}
      </Layout>
    </ContentWrapper>
  );
}
