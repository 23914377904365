import React, {useContext} from 'react';
import {Row} from '../../Widgets';
import styled from 'styled-components';
import {Context} from '../../AppContext';
import {isMonthly} from '../../Utils/UserUtil';
import {navigate} from 'gatsby';
import entries from './entries';
const config = require('../../data.json');

function isActice(url, pathname = '') {
  return pathname.replace(/\//g, '') === url.replace(/\//g, '');
}

export default function Navbar(props) {
  const {pathname} = props;
  const app = useContext(Context);
  const {profile} = app.state;

  return (
    <Wrapper>
      {entries
        .filter((entry) => {
          if (entry.name === 'monthly_manage') {
            return isMonthly(profile);
          }
          return true;
        })
        .map((entry) => {
          return (
            <div
              className={`item ${
                isActice(pathname, entry.name) ? 'active' : ''
              }`}
              key={entry.name}
              onClick={() => {
                if (entry.type === 'function') {
                  return app.actions[entry.name]();
                }

                navigate(`/${entry.name}`);
              }}>
              <div>{entry.label}</div>
              {isActice(pathname, entry.name) && (
                <div style={{fontSize: 12, color: '#9b9b9b'}}>
                  {entry.subtitle}
                </div>
              )}
            </div>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .item {
    color: ${config.colors.text};
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-top: solid 1px ${config.colors.border};
    cursor: default;

    &.active {
      color: ${config.colors.second};
    }

    &:hover {
      color: ${config.colors.second};
    }
  }

  @media only screen and (max-width: ${config.breakpoints.lg}px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    & > .item {
      border-top: 0;
      border-left: solid 1px ${config.colors.border};
      padding: 0 15px;

      &:first-child {
        border-left: 0;
        padding-left: 0;
      }
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    & > .item {
      padding: 0 8px;
    }
  }
`;
